import * as React from "react";
import { useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import IconButton from "@mui/material/IconButton";
import TableContainer from "@mui/material/TableContainer";
import _, { extend, sortBy } from "lodash";
import { format, subDays, addDays } from "date-fns/fp";
import Title from "../../components/Title";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { TableFooter } from "@mui/material";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import TextField from "@mui/material/TextField";
import Divider from "@mui/material/Divider";
import { getStatsByTables, getTotalRequest } from "../../libs/helpers";
import { ExpandMore, ExpandLess } from "@mui/icons-material";

const StatisticTable = ({ user }) => {
  const [data, setData] = useState([]);
  const [selectedInterval, setSelectedInterval] = useState(1);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(addDays(1)(new Date()));
  const [total, setTotal] = useState({
    total: "--",
    totalMinutes: "--",
  });

  useEffect(() => {
    getTableStats();
    setStartDate(subDays(selectedInterval - 1)(new Date()));
    setEndDate(addDays(1)(new Date()));
    getTotal(subDays(selectedInterval - 1)(new Date()), addDays(1)(new Date()));
  }, [user, selectedInterval]);

  const getTableStats = async () => {
    try {
      const response = await getStatsByTables(
        format("yyyy-MM-dd")(subDays(selectedInterval - 1)(new Date())),
        user.id
      );
      const grouppedData = {};
      response.forEach((d) => {
        if (grouppedData[d.order]) {
          grouppedData[d.order].rows.push(d);
          grouppedData[d.order].totalCost += d.totalCost;
          grouppedData[d.order].trainingCost += d.isTraining ? d.totalCost : 0;
          grouppedData[d.order].playTime += d.playTime;
          grouppedData[d.order].trainingTime += d.isTraining ? d.playTime : 0;
        } else {
          grouppedData[d.order] = {
            order: d.order,
            playTime: d.playTime,
            totalCost: d.totalCost,
            trainingCost: d.isTraining ? d.totalCost : 0,
            trainingTime: d.isTraining ? d.playTime : 0,
            rows: [d],
          };
        }
      });
      const extendedData = _.orderBy(
        Object.values(grouppedData),
        "order",
        "asc"
      );
      setData(extendedData);
    } catch (e) {
      console.log(e);
    }
  };

  const addSeparators = (value) => {
    if (!value) {
      return 0;
    }
    if (typeof value === "string") {
      return value;
    }
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const handleEndDate = (date) => {
    if (startDate > date) {
      setEndDate(addDays(1)(date));
      setStartDate(date);
    } else {
      setEndDate(date);
    }
  };

  const handleStartDate = (date) => {
    if (endDate < date) {
      setEndDate(addDays(1)(date));
      setStartDate(date);
    } else {
      setStartDate(date);
    }
  };
  const getTotal = async (start, end) => {
    try {
      const gte = format("yyyy-MM-dd")(start ?? startDate);
      const lte = format("yyyy-MM-dd")(end ?? endDate);
      const res = await getTotalRequest(gte, lte, user.id);
      setTotal(res);
    } catch (e) {
      console.log(e);
    }
  };

  const renderCard = (days) => {
    return (
      <Card
        onClick={() => setSelectedInterval(days)}
        sx={{
          minWidth: 50,
          backgroundColor: days === selectedInterval ? "#71b1f0" : "white",
        }}
      >
        <CardContent>
          <Typography
            textAlign={"center"}
            sx={{ fontSize: 18, fontWeight: "bold" }}
            color="text.secondary"
          >
            {days === 1 ? `Danas` : `${days} dana`}
          </Typography>
        </CardContent>
      </Card>
    );
  };

  const getTableTotal = (key) => {
    return _.sumBy(data, (d) => d[key]);
  };

  const extendRow = (order) => {
    const index = data.findIndex((d) => d.order === order);
    if (index !== -1) {
      const modifiedData = [...data];
      modifiedData[index].isExtended = !modifiedData[index].isExtended;
      setData(modifiedData);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        overflow: "auto",
      }}
    >
      <Box
        sx={{
          marginTop: "10px",
        }}
      >
        <Title>Ukupan promet:</Title>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <Box
            sx={{
              marginRight: "20px",
            }}
          >
            <MobileDatePicker
              label="Od:"
              inputFormat="dd.MM.yyyy."
              value={startDate}
              maxDate={new Date()}
              onChange={handleStartDate}
              onAccept={() => getTotal()}
              renderInput={(params) => <TextField {...params} />}
            />
          </Box>
          <Box>
            <MobileDatePicker
              label="Do:"
              inputFormat="dd.MM.yyyy."
              value={endDate}
              maxDate={addDays(1)(new Date())}
              onChange={handleEndDate}
              onAccept={() => getTotal()}
              renderInput={(params) => <TextField {...params} />}
            />
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            marginTop: "10px",
          }}
        >
          {user.withTrainings ? (
            <>
              <Typography
                sx={{ fontSize: 18, fontWeight: "bold" }}
                color="text.secondary"
              >
                {`Ukupno vreme: ${addSeparators(total.totalMinutes)} minuta`}{" "}
                <br />
                {`- Trening: ${addSeparators(
                  total.totalTrainingMinutes
                )} minuta`}
                <br />
                {`- Standardno: ${addSeparators(
                  total.totalMinutes - total.totalTrainingMinutes
                )} minuta`}
              </Typography>
              <br />
              <Typography
                sx={{ fontSize: 18, fontWeight: "bold" }}
                color="text.secondary"
              >
                {`Ukupan promet: ${addSeparators(total.total)} dinara`} <br />
                {`- Trening: ${addSeparators(total.totalTraining)} dinara`}
                <br />
                {`- Standardno: ${addSeparators(
                  total.total - total.totalTraining
                )} dinara`}
              </Typography>
            </>
          ) : (
            <>
              <Typography
                textAlign={"center"}
                sx={{ fontSize: 18, fontWeight: "bold" }}
                color="text.secondary"
              >
                Ukupno vremen: {addSeparators(total.totalMinutes)} minuta
              </Typography>
              <Typography
                textAlign={"center"}
                sx={{ fontSize: 18, fontWeight: "bold" }}
                color="text.secondary"
              >
                Ukupno naplaćeno: {addSeparators(total.total)} dinara
              </Typography>
            </>
          )}
        </Box>
      </Box>
      <Divider sx={{ my: 3 }} />
      <Title>Statistika po stolovima</Title>
      <Box sx={{ flexGrow: 1, marginBottom: "20px" }}>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            {renderCard(1)}
          </Grid>
          <Grid item xs={4}>
            {renderCard(3)}
          </Grid>
          <Grid item xs={4}>
            {renderCard(7)}
          </Grid>
          <Grid item xs={4}>
            {renderCard(10)}
          </Grid>
          <Grid item xs={4}>
            {renderCard(15)}
          </Grid>
          <Grid item xs={4}>
            {renderCard(30)}
          </Grid>
        </Grid>
      </Box>
      <TableContainer>
        <Table size="small">
          <TableHead sx={{ backgroundColor: "#71b1f0", height: "60px" }}>
            <TableRow>
              <TableCell>Broj stola</TableCell>
              <TableCell>Ukupno minuta</TableCell>
              <TableCell>Ukupno dinara</TableCell>
              {user.withTrainings && (
                <>
                  <TableCell>Ukupno trening minuta</TableCell>
                  <TableCell>Ukupno trening dinara</TableCell>
                </>
              )}
              <TableCell>Prosečno minuta po danu</TableCell>
              <TableCell>Prosečan promet po danu</TableCell>
              <TableCell>Prosečna cena</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row, index) => (
              <React.Fragment key={`${row.order}-${index}`}>
                <TableRow
                  sx={
                    row.isExtended
                      ? { backgroundColor: "#94c6f7" }
                      : {
                          backgroundColor:
                            index % 2 === 0 ? "white" : "#f5f5f5",
                        }
                  }
                >
                  <TableCell onClick={() => extendRow(row.order)}>
                    {addSeparators(row.order)}
                  </TableCell>
                  <TableCell onClick={() => extendRow(row.order)}>
                    {addSeparators(row.playTime)}
                  </TableCell>
                  <TableCell onClick={() => extendRow(row.order)}>
                    {addSeparators(row.totalCost)}
                  </TableCell>
                  {user.withTrainings && (
                    <>
                      <TableCell onClick={() => extendRow(row.order)}>
                        {addSeparators(row.trainingTime)}
                      </TableCell>
                      <TableCell onClick={() => extendRow(row.order)}>
                        {addSeparators(row.trainingCost)}
                      </TableCell>
                    </>
                  )}
                  <TableCell onClick={() => extendRow(row.order)}>
                    {addSeparators(Math.round(row.playTime / selectedInterval))}
                  </TableCell>
                  <TableCell onClick={() => extendRow(row.order)}>
                    {addSeparators(
                      Math.round(row.totalCost / selectedInterval)
                    )}
                  </TableCell>
                  <TableCell onClick={() => extendRow(row.order)}>
                    {addSeparators(Math.round(row.totalCost / row.playTime))}
                  </TableCell>
                  <TableCell padding="checkbox">
                    <IconButton onClick={() => extendRow(row.order)}>
                      {row.isExtended ? <ExpandLess /> : <ExpandMore />}
                    </IconButton>
                  </TableCell>
                </TableRow>
                {row.isExtended && (
                  <>
                    {sortBy(row.rows, "startTime").map((item, i) => (
                      <TableRow
                        sx={{ backgroundColor: "#cbe1f7", height: "20px" }}
                        key={`child-${item.order}-${i}-${item.isExtended}`}
                      >
                        <TableCell
                          colSpan={user.withTrainings ? 9 : 7}
                          sx={{ fontSize: 11, height: "20px" }}
                        >
                          {format("dd.MM.")(new Date(item.startTime))}{" "}
                          {format("hh:mm a")(new Date(item.startTime))} -{" "}
                          {format("hh:mm a")(new Date(item.endTime))} - Vreme{" "}
                          {addSeparators(item.playTime)} min. Cena:{" "}
                          {item.totalCost / item.playTime} din.{" "}
                          {item.isTraining ? "(trening)" : ""}
                        </TableCell>
                      </TableRow>
                    ))}
                  </>
                )}
              </React.Fragment>
            ))}
          </TableBody>
          <TableFooter>
            <TableRow sx={{ backgroundColor: "#71b1f0", height: "60px" }}>
              <TableCell sx={{ fontSize: 14, fontWeight: "bold" }}>
                UKUPNO:
              </TableCell>
              <TableCell sx={{ fontSize: 14, fontWeight: "bold" }}>
                {addSeparators(getTableTotal("playTime"))}
              </TableCell>
              <TableCell sx={{ fontSize: 14, fontWeight: "bold" }}>
                {addSeparators(getTableTotal("totalCost"))}
              </TableCell>
              {user.withTrainings && (
                <>
                  <TableCell sx={{ fontSize: 14, fontWeight: "bold" }}>
                    {addSeparators(getTableTotal("trainingTime"))}
                  </TableCell>
                  <TableCell sx={{ fontSize: 14, fontWeight: "bold" }}>
                    {addSeparators(getTableTotal("trainingCost"))}
                  </TableCell>
                </>
              )}
              <TableCell sx={{ fontSize: 14, fontWeight: "bold" }}>
                {addSeparators(
                  Math.round(getTableTotal("playTime") / selectedInterval)
                )}
              </TableCell>
              <TableCell sx={{ fontSize: 14, fontWeight: "bold" }}>
                {addSeparators(
                  Math.round(getTableTotal("totalCost") / selectedInterval)
                )}
              </TableCell>
              <TableCell />
              <TableCell />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default StatisticTable;
